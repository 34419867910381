const authConfig = async () => {
    const response = await fetch('./environment.json');
    const config = await response.json();

    return {
        userPoolId: config.USER_POOL_ID,
        region: "eu-central-1",
        userPoolWebClientId: config.USER_POOL_CLIENT_ID,
        oauth: {
            domain: config.OAUTH_DOMAIN,
            scope: ['openid'],
            redirectSignIn: config.REDIRECT_SIGN_IN,
            redirectSignOut: config.REDIRECT_SIGN_OUT,
            responseType: 'code',
        },
    };
};


export default authConfig;