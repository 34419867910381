import styled from 'styled-components';
import {Badge as BootstrapBadge, Col} from 'react-bootstrap';
import React from "react";

interface ActiveBadgeProps {
    active: boolean;
}


export const FlexBoxItem = styled.div`
  
  >div{
    font-weight: 500;
    font-size: 14px;
  }
  >div>span{
    color: #aaafbb
  }
  
  flex: 1 1 calc(25% - 20px); /* Take up about 1/4 of the row minus the margin */
  max-width: calc(25% - 20px); /* Ensure no more than 4 items fit per row */
  margin: 10px;
  color: black;
  text-align: left;
`
export const FlexBox = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DevMode = styled.div`
  color: black;
  font-size: 20px;
  width: 100%;
  letter-spacing: 3px;
  margin-top: -7px;
`
export const InfoCardWrapper = styled(Col)`
  font-size: 15px;
  text-align: left;
`
export const SlotDesc = styled(Col)`
  clear: both;
  float: left;
  width: 100%;
  font-size: 10px;

`;

export const ListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderCol = styled(Col)`
  background: #CACCDE;
  padding: 10px 16px;
`;

export const Badge = styled(BootstrapBadge)`
  background-color: ${props => props.backgroundcolor || '#D1E9FF'} !important;
  padding: 4px 8px;
  border-radius: 12px;
  color: #3B82F6;
  font-weight: normal;
`;

export const ActiveBadge = styled(({active, ...props}: ActiveBadgeProps & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />)`
  background: ${(props) => (props.active ? 'green' : 'red')};
  border-radius: 50%;
  width: 12px;
  height: 12px;
`;

export const InitialsBadge = styled.div`
  background: #D8EBFE;
  padding: 4px; 
  border-radius: 50px;
  font-size: 12px;
  color: #2E90FA;
  line-height: 14px;
  margin-right: 5px;
  text-transform: uppercase;
`;
