import { configureStore } from '@reduxjs/toolkit'
import {
    useSelector as rawUseSelector,
    TypedUseSelectorHook,
} from "react-redux";
import userRoleReducer from './features/userRoleSlice'

import {slothAPI} from "redux/api/slothAPI";

export const store = configureStore({
    reducer: {
        userRole: userRoleReducer,
        [slothAPI.reducerPath]: slothAPI.reducer,
    },
    devTools: false, //process.env.NODE_ENV !== 'production',

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([slothAPI.middleware]),
})


//setupSocketListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;